import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IncomingDateModalComponent } from '../../../modal/incoming-date-modal/incoming-date-modal.component';
import { AuthorizationService } from '../../../../core/authorization.service';
import { isNil } from '../../../../core/utils.service';
import { ShipmentFilters } from '../../../../models/filters/shipment-filters-model';
import { Permissions } from '../../../../models/permission-model';
import { Shipment } from '../../../../models/shipment-model';
import { Status } from '../../../../models/status-model';
import { addCheckers } from '../../../../shared/checkers.service';
import { ImportExportService } from '../../../../shared/import-export.service';
import { ShipmentsService } from '../../../../shared/shipments.service';
import { ENTITIES } from 'src/app/entities';
import { GenericConfirmationModalComponent } from '@container/modal/generic-confirmation-modal/generic-confirmation-modal.component';

@Component({
  selector: 'app-main-shipments-list',
  templateUrl: './main-shipments-list.component.html',
  styleUrls: ['./main-shipments-list.component.css'],
})
export class MainShipmentsListComponent implements OnInit {
  constructor(
    private shipmentsService: ShipmentsService,
    private router: Router,
    private importExportService: ImportExportService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService
  ) {}

  dataSource: MatTableDataSource<Shipment>;

  displayedColumns = [
    'check',
    'week',
    'lot',
    'shipmentCode',
    'orderId',
    'statusCheck',
    'origin',
    'extra',
    'crossUkBorder',
    'status',
    'paymentStatus',
    'backorder',
    'category',
    'totalAcceptedQuantity',
    'totalAcceptedValue',
    'totalAcceptedPurchasePrice',
    'totalVirtualAcceptedPurchasePrice',
    'totalShippedQuantity',
    'totalShippedValue',
    'totalPurchasePrice',
    'totalVirtualPurchasePrice',
    'margin',
    'percentMargin',
    'virtualMargin',
    'virtualPercentMargin',
    'vendor',
    'destination',
    'latestDeliveryDate',
    'latestPickupDate',
    'requestForPayment',
    'requestForPaymentDate',
    'payment',
    'paymentDate',
    'numberOfCartons',
    'numberOfPallets',
    'weight',
    'shipmentDate',
    'asnCode',
    'sentAsn',
    'invoiceNumber',
    'carrierContract',
    'trackingNumber',
    'expectedDeliveryDate',
    'earliestDeliveryDate',
    'deliveryDate',
    'ddtNumber',
    'hasTransit',
    'ukIntercoInvoice',
    'ukIntercoInvoiceValidData',
    'ukIntercoInvoiceStatus',
  ];

  mainChecker = false;

  bulkActionsActive = false;

  dataShipments: any[] = [];

  paymentStatus: Status[];

  hasShipmentManagement: Observable<boolean>;
  hasChangeShipmentPaymentStatus: Observable<boolean>;

  currentFileUpload: File;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('getFile', { static: false }) getFile: ElementRef;

  ngOnInit() {
    this.resultsNumber = this.shipmentsService.resultsNumber;

    if (this.shipmentsService.shipmentFilters === undefined) {
      this.shipmentsService.shipmentFilters = new ShipmentFilters();
    }
    this.resetCheckers();
    this.dataSource = new MatTableDataSource([]);
    this.loadShipments(this.shipmentsService.shipmentFilters);

    this.hasShipmentManagement = this.authorizationService.hasPermission(Permissions.ShipmentManagement);
    this.hasChangeShipmentPaymentStatus = this.authorizationService.hasPermission(Permissions.ChangeShipmentPaymentStatus);

    this.shipmentsService.getShipmentPaymentStatus().subscribe((result) => (this.paymentStatus = result));
  }

  loadShipments(filters: ShipmentFilters) {
    this.shipmentsService.getFilteredShipments(filters).subscribe((ship: Shipment[]) => {
      this.dataShipments = addCheckers(ship, this.shipmentsService.selectedShipments, this.mainChecker);
      this.dataSource.data = this.dataShipments;
    });
  }

  loadPage() {
    this.shipmentsService.shipmentFilters.pageNumber = this.paginator.pageIndex;
    this.shipmentsService.shipmentFilters.pageSize = this.paginator.pageSize;
    this.loadShipments(this.shipmentsService.shipmentFilters);
  }

  onSort() {
    this.shipmentsService.shipmentFilters.sortBy = this.sort.active;
    this.shipmentsService.shipmentFilters.sortDirection = this.sort.direction;
    this.loadShipments(this.shipmentsService.shipmentFilters);
  }

  onSelected(value: boolean, selectedShipment: any) {
    const selectedIndex = this.dataShipments.indexOf(selectedShipment);
    this.dataShipments[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.shipmentsService.addShipmentIdToSelected(this.dataShipments[selectedIndex].id);
    } else {
      this.shipmentsService.removeShipmentIdFromSelected(this.dataShipments[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.shipmentsService.shipmentMainChecker = this.mainChecker;
    for (const i of this.dataShipments) {
      i.checked = value;
    }
    this.shipmentsService.selectedShipments = [];
    this.bulkActionsActive = value;
  }

  applyFilter(filters: ShipmentFilters) {
    this.paginator.pageIndex = 0;
    this.dataShipments = [];
    this.resetCheckers();
    this.loadShipments(filters);
  }

  resetCheckers() {
    this.shipmentsService.selectedShipments = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  showDetails() {
    this.router.navigate(['shipments', 'details']);
  }

  export(format: string, onlyHeaders: boolean) {
    this.importExportService
      .exportShipments(
        this.shipmentsService.selectedShipments,
        format,
        this.mainChecker,
        this.shipmentsService.shipmentFilters,
        onlyHeaders
      )
      .subscribe((resp) => {
        console.log(resp);
      });
  }

  merge() {
    this.shipmentsService.merge(this.mainChecker).subscribe((response: string) => {
      this.applyFilter(this.shipmentsService.shipmentFilters);
      this.snackBar.open(response, 'CLOSE')._dismissAfter(2000);
    });
  }

  changePaymentStatus(status: string) {
    this.shipmentsService
      .changePaymentStatus(null, status, this.shipmentsService.selectedShipments, this.shipmentsService.shipmentFilters, this.mainChecker)
      .subscribe(() => {
        this.applyFilter(this.shipmentsService.shipmentFilters);
      });
  }

  changeIncomingDate() {
    const dialogRef = this.dialog.open(IncomingDateModalComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((incomingDate: Date) => {
      if (!isNil(incomingDate)) {
        this.shipmentsService
          .changeIncomingDate(
            null,
            incomingDate,
            this.shipmentsService.selectedShipments,
            this.shipmentsService.shipmentFilters,
            this.mainChecker
          )
          .subscribe(() => {
            this.applyFilter(this.shipmentsService.shipmentFilters);
          });
      }
    });
  }

  importTracking() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '300px',
      data: {
        message: `------------------------------------- Select a file with -------------------------------------\n
         - Format: .csv
         - Columns: OrderId; Tracking Number; Shipment Date (dd/MM/yyyy).\n

         NB: Use the column header as first row.`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.getFile) {
          this.getFile.nativeElement.click();
        }
      }
    });
  }

  fileChanged(event) {
    this.currentFileUpload = event.target.files[0];
    const substring: any[] = this.currentFileUpload.name.split('.');
    const extension: string = substring[substring.length - 1].toLowerCase();

    if (extension === 'csv') {
      this.snackBar.open('Uploading', '')._dismissAfter(3000);
      this.importExportService.uploadFile(this.currentFileUpload, ENTITIES.UPDATE_SHIPMENT_TRACKING).subscribe(() => {});
    } else {
      this.snackBar.open('Wrong File Extension. Allowed format .csv')._dismissAfter(4000);
    }
  }
}
