<mat-toolbar class="lot-edit-bulk-actions">
  <div class="container">
    <div class="row">
      <div class="col-md-9">
        <button
          *ngIf="hasChangeShipmentPaymentStatus | async"
          [disabled]="!bulkActionsActive"
          [matMenuTriggerFor]="changePaymentStatusMenu"
          class="box-shadow-none btn-custom payment-status-button margin-right-6"
        >
          Change Payment Status <i class="fa fa-angle-down sel-icon" aria-hidden="true"></i>
        </button>
        <mat-menu #changePaymentStatusMenu="matMenu">
          <button mat-menu-item *ngFor="let s of paymentStatus" (click)="changeShipmentsPaymentStatus(s.code)">{{ s.description }}</button>
        </mat-menu>

        <button
          *ngIf="hasShipmentManagement | async"
          [disabled]="!bulkActionsActive"
          (click)="changeShipmentsIncomingDate()"
          class="box-shadow-none btn-custom margin-right-6"
        >
          Change Incoming Date
        </button>

        <button *ngIf="hasCancelShipment | async" (click)="clearUnorderedShipments()" class="box-shadow-none btn-custom margin-right-6">
          Delete unordered shipments
        </button>

        <button class="box-shadow-none btn-custom margin-right-6" (click)="alignShipmentQuantities()">
          <i class="fa fa-upload margin-right-6"></i> Align quantities
        </button>

        <input
          #alignQuantitiesBtn
          type="file"
          style="display: none"
          onclick="this.value = null"
          (change)="uploadAlignQuantitiesFile($event)"
        />
      </div>

      <div class="col-md-2">
        <button (click)="importTracking()" class="box-shadow-none btn-custom margin-right-6">
          <i class="fa fa-upload margin-right-6" aria-hidden="true"></i> Import Tracking
        </button>

        <input
          #importTrackingBtn
          type="file"
          style="display: none"
          onclick="this.value = null"
          (change)="uploadShipmentTrackingFile($event)"
        />

        <button
          [disabled]="!bulkActionsActive"
          [matMenuTriggerFor]="exportShipmentsChoice"
          class="box-shadow-none btn-custom margin-right-6"
        >
          <i class="fa fa-external-link margin-right-6" aria-hidden="true"></i>Export
        </button>

        <mat-menu #exportShipmentsChoice="matMenu">
          <button mat-menu-item [disabled]="!bulkActionsActive" [matMenuTriggerFor]="exportShipmentHeaderChoice">Export headers</button>
          <mat-menu #exportShipmentHeaderChoice="matMenu">
            <button mat-menu-item (click)="exportExcel('excel', true)">Save as Excel</button>
            <button mat-menu-item (click)="exportExcel('csv', true)">Save as CSV</button>
          </mat-menu>

          <button mat-menu-item [disabled]="!bulkActionsActive" [matMenuTriggerFor]="exportShipmentItemsChoice">Export items</button>
          <mat-menu #exportShipmentItemsChoice="matMenu">
            <button mat-menu-item (click)="exportExcel('excel', false)">Save as Excel</button>
            <button mat-menu-item (click)="exportExcel('csv', false)">Save as CSV</button>
          </mat-menu>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
