import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-confirmation-modal',
  templateUrl: './generic-confirmation-modal.component.html',
  styleUrls: ['./generic-confirmation-modal.component.css'],
})
export class GenericConfirmationModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GenericConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string | { message: string; question: string }
  ) {}

  message: string;
  question = 'Are you sure you want to continue?';

  ngOnInit() {
    if (typeof this.data === 'string') {
      this.message = this.data.replace(/\n/g, '<br>');
    } else {
      this.message = this.data.message.replace(/\n/g, '<br>');
      this.question = this.data.question;
    }
  }
}
